import PropTypes from "prop-types";
import React, { useState } from "react";
import { fetchIsPinnedCollection } from "../../utils/apis";
import { Button, Flex, Text, Modal } from "@mantine/core";
const IsPinnedCollectionWindow = ({
  collectionID,
  isPinned,
  setNameCollectionIsPinned,
  openedModalPinned,
  onOpenedModalPinned,
  theme,
}) => {
  // Pinned Collection name
  const handleClickIsPinnedCollection = async () => {
    fetchIsPinnedCollection(
      collectionID,
      isPinned,
      onOpenedModalPinned,
      setNameCollectionIsPinned,
    );
  };
  const [blackButtonIsHovered, setBlackButtonIsHovered] = useState(false);

  const [whiteButtonIsHovered, setWhiteButtonIsHovered] = useState(false);
  return (
    <Modal
      opened={openedModalPinned}
      onClose={() => onOpenedModalPinned(false)}
      centered
      size="22rem"
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
    >
      <Text>
        {isPinned
          ? "By pinning this collection, you will be able to select it on the Stamp page dropdown menu."
          : "This collection will no longer appear in the Stamp menu. You can always pin it again later."}
      </Text>

      <Text
        styles={() => ({
          root: {
            fontSize: "1.5em",
            textAlign: "center",
            marginTop: "1em",
            marginBottom: "1em",
          },
        })}
      >
        Proceed?
      </Text>

      <Flex justify="center" gap="20" mb={20}>
        <Button
          type="submit"
          variant="filled"
          color={theme.customColors.black}
          classNames={{
            root: "modal-button-submit",
          }}
          styles={() => ({
            root: {
              fontSize: "1.2em",
              color: blackButtonIsHovered
                ? theme.customColors.black
                : theme.customColors.white,
              backgroundColor: blackButtonIsHovered
                ? theme.customColors.white
                : theme.customColors.black,
              border: "1px solid " + theme.customColors.black,
            },
          })}
          onMouseEnter={() => setBlackButtonIsHovered(true)}
          onMouseLeave={() => setBlackButtonIsHovered(false)}
          onClick={() => handleClickIsPinnedCollection()}
        >
          Yes
        </Button>

        <Button
          type="submit"
          variant="filled"
          color={theme.customColors.black}
          styles={() => ({
            root: {
              fontSize: "1.2em",
              color: whiteButtonIsHovered
                ? theme.customColors.white
                : theme.customColors.black,
              backgroundColor: whiteButtonIsHovered
                ? theme.customColors.black
                : theme.customColors.white,
              border: "1px solid " + theme.customColors.black,
            },
          })}
          onMouseEnter={() => setWhiteButtonIsHovered(true)}
          onMouseLeave={() => setWhiteButtonIsHovered(false)}
          onClick={() => onOpenedModalPinned(false)}
        >
          No
        </Button>
      </Flex>
    </Modal>
  );
};

IsPinnedCollectionWindow.propTypes = {
  collectionID: PropTypes.string.isRequired,
  isPinned: PropTypes.bool.isRequired,
  setNameCollectionIsPinned: PropTypes.func.isRequired,
  openedModalPinned: PropTypes.bool.isRequired,
  onOpenedModalPinned: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    customColors: PropTypes.shape({
      black: PropTypes.string.isRequired,
      white: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
export default IsPinnedCollectionWindow;
