import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { fetchCreateNameCollection } from "../utils/apis";
import { sha3_256_hash_string } from "../utils/cryptoUtils";
import { formatStampDetails } from "../utils/formatStampDetails";
import { Text, Button, Flex, Box, useMantineTheme, Modal } from "@mantine/core";

const CollectionCreatingModal = ({
  titleConfirmation = "",
  textConfirmation = "",
  titleLoading = "",
  textLoading = "",
  nameCollection = "",
  onNameCollectionError,
  onNameCollectionSuccess,
  onNameCollection = "",
  onShowConfirmationCollectionCreate,
  onUpdateStampQuota,
  opened,
  close,
  form,
}) => {
  // Confirmation create Collection an show loading
  const [loading, setLoading] = useState(false);

  const [blackButtonIsHovered, setBlackButtonIsHovered] = useState(false);

  const [whiteButtonIsHovered, setWhiteButtonIsHovered] = useState(false);

  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  // Memoize the callback function to avoid unnecessary re-renders
  const handleLoading = useCallback((loading) => {
    setLoading(loading);
  }, []);

  // Fetch Create Collection
  const handleCreateCollection = async () => {
    const requestData = {
      // Name of Collection
      name: nameCollection,
    };

    fetchCreateNameCollection(
      requestData,
      onNameCollectionError,
      onNameCollectionSuccess,
      onNameCollection,
      onShowConfirmationCollectionCreate,
      onUpdateStampQuota,
      handleLoading,
      close,
      form,
    );
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to update `isMobile` state based on screen width
    const updateScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateScreenSize);

    // Set the initial value for `isMobile`
    updateScreenSize();

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const hashCollectionSha3 = sha3_256_hash_string(nameCollection);

  return (
    <Modal
      opened={opened}
      onClose={close}
      centered
      size="xl"
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
    >
      {loading && (
        <Flex
          justify="center"
          style={() => ({
            height: "6rem",
          })}
        >
          <motion.span
            style={{
              display: "block",
              width: "5rem",
              height: "5rem",
              border: "0.75rem solid " + theme.customColors.animationLoader,
              borderTop: "0.75rem solid " + theme.customColors.black,
              borderRadius: "50%",
              position: "absolute",
            }}
            animate={{
              rotate: 360,
            }}
            transition={{
              repeat: Infinity,
              duration: 1,
              ease: "linear",
            }}
          />
        </Flex>
      )}

      {loading ? (
        <>
          <Flex justify="center">
            <Text
              classNames={{
                root: "collection-names-button",
              }}
              styles={() => ({
                root: {
                  fontSize: "1.45em",
                  marginBottom: "1rem",
                },
              })}
            >
              {titleLoading}
            </Text>
          </Flex>
          <Text
            styles={(theme) => ({
              root: {
                fontSize: "1em",
                fontFamily: theme.headings.fontFamily.light,
                marginBottom: "0.5rem",
                textAlign: "center",
                marginTop: "1rem",
              },
            })}
          >
            {" "}
            {textLoading}
          </Text>
        </>
      ) : (
        <>
          <Flex justify="center">
            <Text
              styles={() => ({
                root: {
                  fontSize: "1.45em",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                },
              })}
            >
              {titleConfirmation}
            </Text>
          </Flex>
          <Box>
            <Text
              component="span"
              styles={(theme) => ({
                root: {
                  fontSize: "1em",
                  fontFamily: theme.headings.fontFamily.light,
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                },
              })}
            >
              Collection Name:
            </Text>
            <Text
              component="span"
              classNames={{
                root: "modal-collection-name",
              }}
              styles={(theme) => ({
                root: {
                  fontSize: "1em",
                  fontFamily: theme.headings.fontFamily.light,
                  marginBottom: "0.5rem",
                },
              })}
            >
              {" "}
              {nameCollection}
            </Text>
          </Box>
          <Box>
            <Text
              component="span"
              styles={(theme) => ({
                root: {
                  fontSize: "1em",
                  fontFamily: theme.headings.fontFamily.light,
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                },
              })}
            >
              Collection ID (SHA3-256):
            </Text>
            <Text
              component="span"
              styles={(theme) => ({
                root: {
                  fontSize: "1em",
                  fontFamily: theme.headings.fontFamily.light,
                  marginBottom: "0.5rem",
                },
              })}
            >
              {isMobile ? (
                <> {formatStampDetails(hashCollectionSha3)}</>
              ) : (
                <> {hashCollectionSha3}</>
              )}
            </Text>
          </Box>
          <Text
            styles={(theme) => ({
              root: {
                fontSize: "1em",
                fontFamily: theme.headings.fontFamily.light,
                marginBottom: "0.5rem",
                textAlign: "center",
                marginTop: "1rem",
              },
            })}
          >
            {" "}
            {textConfirmation}
          </Text>

          <Flex justify="center">
            <Text
              styles={() => ({
                root: {
                  fontSize: "1.45em",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                  fontWeight: "bold",
                },
              })}
            >
              Create Collection?
            </Text>
          </Flex>
          <Flex justify="center" gap="20" mb={20}>
            <Button
              type="submit"
              variant="filled"
              color={theme.customColors.black}
              classNames={{
                root: "modal-button-submit",
              }}
              styles={() => ({
                root: {
                  fontSize: "1.2em",
                  color: blackButtonIsHovered
                    ? theme.customColors.black
                    : theme.customColors.white,
                  backgroundColor: blackButtonIsHovered
                    ? theme.customColors.white
                    : theme.customColors.black,
                  border: "1px solid " + theme.customColors.black,
                },
              })}
              onMouseEnter={() => setBlackButtonIsHovered(true)}
              onMouseLeave={() => setBlackButtonIsHovered(false)}
              onClick={() => handleCreateCollection()}
            >
              Yes
            </Button>

            <Button
              type="submit"
              variant="filled"
              color={theme.customColors.black}
              styles={() => ({
                root: {
                  fontSize: "1.2em",
                  color: whiteButtonIsHovered
                    ? theme.customColors.white
                    : theme.customColors.black,
                  backgroundColor: whiteButtonIsHovered
                    ? theme.customColors.black
                    : theme.customColors.white,
                  border: "1px solid " + theme.customColors.black,
                },
              })}
              onMouseEnter={() => setWhiteButtonIsHovered(true)}
              onMouseLeave={() => setWhiteButtonIsHovered(false)}
              onClick={() => close()}
            >
              No
            </Button>
          </Flex>
        </>
      )}
    </Modal>
  );
};

CollectionCreatingModal.propTypes = {
  titleConfirmation: PropTypes.string.isRequired,
  textConfirmation: PropTypes.string.isRequired,
  titleLoading: PropTypes.string.isRequired,
  textLoading: PropTypes.string.isRequired,
  nameCollection: PropTypes.string.isRequired,
  onNameCollectionError: PropTypes.func.isRequired,
  onNameCollectionSuccess: PropTypes.func.isRequired,
  onNameCollection: PropTypes.func.isRequired,
  onShowConfirmationCollectionCreate: PropTypes.func.isRequired,
  onUpdateStampQuota: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  open: PropTypes.func,
  close: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

export default CollectionCreatingModal;
