import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import {
  fetchAllCollectionsIsPinned,
  fetchAllCollectionsIsNotPinned,
  URLs,
} from "../../utils/apis";
import IsPinnedCollectionWindow from "./IsPinnedCollectionWindow";

import {
  Title,
  Text,
  Button,
  Flex,
  Box,
  TextInput,
  Anchor,
  useMantineTheme,
  rem,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  collectionPinnedTableData,
  collectionPinnedTableColumns,
  collectionPinnedTableOptions,
} from "../../utils/UserProfile/collectionPinnedTable";
import {
  collectionUnPinnedTableData,
  collectionUnPinnedTableColumns,
  collectionUnPinnedTableOptions,
} from "../../utils/UserProfile/collectionUnPinnedTable";
import { useTableIcons } from "../../utils/collectionFound/resultScreen";

import { IconChevronLeft } from "@tabler/icons-react";

const CollectionNames = ({
  onNameCollection,
  nameCollectionSuccess,
  open,
  form,
}) => {
  const [blackButtonIsHovered, setBlackButtonIsHovered] = useState(false);

  const [blackLinkIsHovered, setBlackLinkIsHovered] = useState(false);

  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    usercollections: [],
    current_page: 1,
    total_pages: 1,
  });

  const [inputValuesIsNotPinned, setInputValuesIsNotPinned] = useState({
    usercollections: [],
    current_page: 1,
    total_pages: 1,
  });

  //Successfully is Pinned name
  const [nameCollectionIsPinned, setNameCollectionIsPinned] = useState("");

  // Collection Name id
  const [collectionID, setCollectionID] = useState("");

  // Collection Is Pinned
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    fetchAllCollectionsIsPinned(1, setInputValues);
    fetchAllCollectionsIsNotPinned(1, setInputValuesIsNotPinned);
  }, [nameCollectionSuccess, nameCollectionIsPinned]); // updating the list after adding a new Collection Name

  // Icons used within the tables, provided through a custom utility function
  const faIcons = useMemo(() => useTableIcons(), []);

  const [openedModalPinned, setOpenedModalPinned] = useState(false);

  const dataPinned = useMemo(
    () =>
      collectionPinnedTableData(
        inputValues["usercollections"],
        setCollectionID,
        setIsPinned,
        setOpenedModalPinned,
        theme,
      ),
    [
      inputValues["usercollections"],
      setCollectionID,
      setIsPinned,
      setOpenedModalPinned,
      theme,
    ],
  );

  // Column definitions for the main table using a utility function
  const columnsPinned = collectionPinnedTableColumns();

  // Configuration options for the main table using Mantine's table library
  const tablePinned = useMantineReactTable(
    collectionPinnedTableOptions(columnsPinned, dataPinned, faIcons, theme),
  );

  const dataUnPinned = useMemo(
    () =>
      collectionUnPinnedTableData(
        inputValuesIsNotPinned["usercollections"],
        setCollectionID,
        setIsPinned,
        setOpenedModalPinned,
        theme,
      ),
    [
      inputValuesIsNotPinned["usercollections"],
      setCollectionID,
      setIsPinned,
      setOpenedModalPinned,
      theme,
    ],
  );

  // Column definitions for the main table using a utility function
  const columnsUnPinned = collectionUnPinnedTableColumns();

  // Configuration options for the main table using Mantine's table library
  const tableUnPinned = useMantineReactTable(
    collectionUnPinnedTableOptions(
      columnsUnPinned,
      dataUnPinned,
      faIcons,
      theme,
    ),
  );
  return (
    <Box
      style={() => ({
        paddingTop: "5rem",
        paddingBottom: "6rem",
      })}
    >
      <Title
        order={2}
        styles={(theme) => ({
          root: {
            fontFamily: theme.headings.fontFamily.secondary,
            paddingBottom: "4rem",
            textAlign: "center",
            fontSize: "3em",
            fontWeight: "normal",
          },
        })}
      >
        Create a Collection
      </Title>

      <form
        onSubmit={form.onSubmit(async (values) => {
          onNameCollection(values.collectionName);
          open();
        })}
      >
        <Flex
          gap={10}
          mb={32}
          align="center"
          wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          justify={tabletMediaQuery ? "flex-start" : "center"}
          styles={() => ({
            root: {
              display: tabletMediaQuery ? "block" : "flex",
            },
          })}
        >
          <TextInput
            classNames={{
              input: "collection-names-input",
            }}
            styles={() => ({
              root: {
                display: tabletMediaQuery ? "block" : "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: tabletMediaQuery ? "flex-start" : "center",
                maxWidth: "100%",
              },
              label: {
                paddingRight: 20,
                fontFamily: theme.headings.fontFamily.bold,
                fontSize: "1.2em",
                width: 236,
                textAlign: tabletMediaQuery ? "left" : "right",
              },
              input: {
                paddingRight: 20,
                border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                maxWidth: "100%",
                width: tabletMediaQuery ? "100%" : "22rem",
              },
            })}
            label="New collection name:"
            inputWrapperOrder={["label", "input"]}
            inputContainer={(children) => (
              <Box>
                {children}
                <Box
                  className="collection-error-text"
                  style={(theme) => ({
                    fontSize: "0.93em",
                    lineHeight: "0.93em",
                    position: "absolute",
                    color: theme.customColors.red,
                    fontFamily: theme.headings.fontFamily.light,
                    width: tabletMediaQuery ? "16rem" : "23rem",
                  })}
                >
                  {form.errors.collectionName}
                </Box>
                <Box
                  className="collection-created-text"
                  style={(theme) => ({
                    fontSize: "0.93em",
                    lineHeight: "0.93em",
                    position: "absolute",
                    color: theme.customColors.green,
                    fontFamily: theme.headings.fontFamily.light,
                    width: tabletMediaQuery ? "16rem" : "23rem",
                  })}
                >
                  {nameCollectionSuccess}
                </Box>
              </Box>
            )}
            placeholder="Enter new collection name"
            key={form.key("collectionName")}
            {...form.getInputProps("collectionName")}
          />
          <Button
            type="submit"
            variant="filled"
            classNames={{
              root: "collection-names-button",
            }}
            styles={() => ({
              root: {
                fontSize: "1.2em",
                border: "1px solid " + theme.customColors.black,
                marginTop: tabletMediaQuery ? "1.8rem" : "",
                color: blackButtonIsHovered
                  ? theme.customColors.black
                  : theme.customColors.white,
                backgroundColor: blackButtonIsHovered
                  ? theme.customColors.white
                  : theme.customColors.black,
              },
            })}
            onMouseEnter={() => setBlackButtonIsHovered(true)}
            onMouseLeave={() => setBlackButtonIsHovered(false)}
          >
            Create
          </Button>
        </Flex>
      </form>

      <Title
        order={2}
        styles={(theme) => ({
          root: {
            fontFamily: theme.headings.fontFamily.secondary,
            paddingTop: "0.5rem",
            paddingBottom: "2.5rem",
            textAlign: "center",
            fontSize: "3em",
            fontWeight: "normal",
          },
        })}
      >
        Manage Collections
      </Title>

      <IsPinnedCollectionWindow
        collectionID={collectionID}
        isPinned={isPinned}
        setNameCollectionIsPinned={setNameCollectionIsPinned}
        openedModalPinned={openedModalPinned}
        onOpenedModalPinned={setOpenedModalPinned}
        theme={theme}
      />

      <Box
        style={(theme) => ({
          backgroundColor: theme.customColors.white,
          paddingTop: "1.5em",
          paddingBottom: "2em",
          paddingLeft: "1.5em",
          paddingRight: "1em",
        })}
      >
        <Text
          styles={(theme) => ({
            root: {
              fontSize: "1.5em",
              paddingBottom: "1rem",
              paddingLeft: "1.5rem",
              fontFamily: theme.headings.fontFamily.bold,
            },
          })}
        >
          Pinned Collections
        </Text>
        <Text
          styles={() => ({
            root: {
              paddingBottom: "1rem",
              paddingLeft: "1.5rem",
            },
          })}
        >
          Pinned Collections are available to be selected on the Stamp page.
        </Text>

        {inputValues["usercollections"].length > 0 ? (
          <MantineReactTable mb="lg" table={tablePinned} />
        ) : (
          <Text
            styles={() => ({
              root: {
                paddingLeft: "1.5rem",
              },
            })}
          >
            No Collections
          </Text>
        )}
      </Box>

      <Box
        style={(theme) => ({
          backgroundColor: theme.customColors.white,
          marginTop: "3em",
          paddingTop: "1.5em",
          paddingBottom: "2em",
          paddingLeft: "1.5em",
          paddingRight: "1em",
          marginBottom: "3em",
        })}
      >
        <Text
          styles={(theme) => ({
            root: {
              fontSize: "1.5em",
              paddingBottom: "1rem",
              paddingLeft: "1.5rem",
              fontFamily: theme.headings.fontFamily.bold,
            },
          })}
        >
          All Unpinned Collections
        </Text>
        <Text
          styles={() => ({
            root: {
              paddingBottom: "1rem",
              paddingLeft: "1.5rem",
            },
          })}
        >
          Unpinned Collections are those that you’ve created but are not pinned
          on the Stamp page menu.
        </Text>

        {inputValuesIsNotPinned["usercollections"].length > 0 ? (
          <MantineReactTable mb="lg" table={tableUnPinned} />
        ) : (
          <Text
            styles={() => ({
              root: {
                paddingLeft: "1.5rem",
              },
            })}
          >
            No Collections
          </Text>
        )}
      </Box>

      <Anchor
        href={URLs.STAMP_URL}
        underline="never"
        styles={() => ({
          root: {
            fontSize: "1.1em",
            border: "1px solid " + theme.customColors.black,
            borderRadius: "0.3em",
            padding: "0.5em 1em",
            color: blackButtonIsHovered
              ? theme.customColors.black
              : theme.customColors.white,
            backgroundColor: blackButtonIsHovered
              ? theme.customColors.white
              : theme.customColors.black,
          },
        })}
        onMouseEnter={() => {
          setBlackButtonIsHovered(true);
          setBlackLinkIsHovered(true);
        }}
        onMouseLeave={() => {
          setBlackButtonIsHovered(false);
          setBlackLinkIsHovered(false);
        }}
      >
        <IconChevronLeft
          style={{
            width: rem(21),
            height: rem(33),
            stroke: blackLinkIsHovered
              ? theme.customColors.black
              : theme.customColors.white,
          }}
        />
        Back to Stamper
      </Anchor>
    </Box>
  );
};
CollectionNames.propTypes = {
  onUpdateStampQuota: PropTypes.func,
  onShowConfirmationCollectionCreate: PropTypes.func.isRequired,
  showConfirmationCollectionCreate: PropTypes.bool,
  nameCollection: PropTypes.string.isRequired,
  nameCollectionSuccess: PropTypes.string.isRequired,
  nameCollectionError: PropTypes.bool.isRequired,
  onNameCollectionError: PropTypes.func.isRequired,
  onNameCollection: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
};
export default CollectionNames;
