import React from "react";
import { Flex, Anchor } from "@mantine/core";
import moment from "moment/moment";

const formattedDateTime = (date) =>
  moment(date, "YYYY-MM-DD hh:mm:ss").format("MM/DD/YY");

// Function for creating data
export const billingHistoryTableData = (contentBillingHistory) => {
  if (!Array.isArray(contentBillingHistory)) {
    return [];
  }

  return contentBillingHistory.map((item) => ({
    invoice_number: (
      <Anchor
        underline="always"
        href={item.download_url}
        target="_blank"
        rel="noopener noreferrer"
        styles={(theme) => ({
          root: {
            color: theme.customColors.black,
          },
        })}
      >
        {item.invoice_number}{" "}
      </Anchor>
    ),

    subscription_name: item.subscription_name,
    billed_at: formattedDateTime(item.billed_at),
    billing_period: `${formattedDateTime(
      item.billing_period.start,
    )} - ${formattedDateTime(item.billing_period.end)}`,
    transaction: <Flex justify="center">{item.formatted_amount}</Flex>,
  }));
};

// Function for creating columns
export const billingHistoryTableColumns = () => [
  {
    accessorKey: "invoice_number",
    header: "Invoice Number",
    enableSorting: true,
    size: 100,
    sortingFn: (rowA, rowB) => {
      const invoiceA = rowA.original.invoice_number
        ? String(rowA.original.invoice_number)
        : "";
      const invoiceB = rowB.original.invoice_number
        ? String(rowB.original.invoice_number)
        : "";

      const [prefixA, suffixA] = invoiceA.split("-").map(Number);
      const [prefixB, suffixB] = invoiceB.split("-").map(Number);

      // Handle cases where prefix or suffix is NaN
      if (isNaN(prefixA) || isNaN(suffixA)) return 1; // Treat invalid values as greater
      if (isNaN(prefixB) || isNaN(suffixB)) return -1;

      // Compare prefixes first
      if (prefixA < prefixB) return -1;
      if (prefixA > prefixB) return 1;

      // If prefixes are equal, compare suffixes
      if (suffixA < suffixB) return -1;
      if (suffixA > suffixB) return 1;

      return 0; // Equal
    },
  },
  {
    accessorKey: "subscription_name",
    header: "Product Name",
    size: 200,
    sortingFn: (rowA, rowB) => {
      const cidA = String(rowA.original.subscription_name || "").toLowerCase();
      const cidB = String(rowB.original.subscription_name || "").toLowerCase();

      if (cidA < cidB) return -1;
      if (cidA > cidB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "billed_at",
    header: "Billed At",
    size: 50,
    cell: ({ row }) => formattedDateTime(row.original.billed_at), // Render formatted date
    sortingFn: (rowA, rowB) => {
      const dateFirst = moment(rowA.original.billed_at, "MM/DD/YY").toDate();
      const dateSecond = moment(rowB.original.billed_at, "MM/DD/YY").toDate();

      if (dateFirst < dateSecond) return -1;
      if (dateFirst > dateSecond) return 1;
      return 0;
    },
  },
  {
    accessorKey: "billing_period",
    header: "Billing Period",
    enableSorting: false,
    size: 150,
  },
  {
    accessorKey: "transaction",
    header: "Amount",
    size: 100,
    sortingFn: (rowA, rowB) => {
      const getTransactionValue = (transaction) => {
        // Assuming the transaction string is like "$500"
        const value = transaction?.props?.children || "";
        return parseFloat(value.replace(/[^0-9.-]+/g, "")); // Remove non-numeric characters and convert to number
      };

      const transactionFirst = getTransactionValue(rowA.original.transaction);
      const transactionSecond = getTransactionValue(rowB.original.transaction);

      if (transactionFirst < transactionSecond) return -1;
      if (transactionFirst > transactionSecond) return 1;
      return 0;
    },
  },
];

// Function for configuring the table
export const billingHistoryTableOptions = (columns, data, faIcons, theme) => ({
  columns,
  data,
  icons: faIcons,
  paginationDisplayMode: "pages",
  enableFullScreenToggle: false,
  enableTopToolbar: false,
  enableBottomToolbar: true,
  enableColumnActions: false,
  initialState: { pagination: { pageIndex: 0, pageSize: 12 } },
  withColumnBorders: true,
  mantinePaginationProps: () => ({
    showRowsPerPage: false,
    color: theme.customBackgrounds.table,
    autoContrast: true,
    style: {
      paddingBottom: "1.75em",
      paddingLeft: "0.5em",
    },
  }),
  mantineTableProps: {
    style: {
      border: "none",
      borderCollapse: "collapse",
    },
    className: "html-table html-table-custom",
  },
  mantinePaperProps: {
    withBorder: false,
    shadow: "none",
    className: "html-table-custom-wrap",
  },
  mantineTableBodyCellProps: {
    style: {
      padding: "0.6875em 0.9375em 0.5625em 0.9375em",
    },
  },
  mantineTableHeadCellProps: {
    style: {
      padding: "0.55em 0.9375em 0.4375em 0.9375em",
    },
  },
  mantineTableBodyRowProps: () => ({
    style: {
      borderBottom: theme.customColors.tableBorder,
    },
  }),
  mantineTableHeadRowProps: () => ({
    style: {
      backgroundColor: theme.customBackgrounds.table,
    },
  }),
});
