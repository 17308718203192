import React from "react";
import {
  ActionIcon,
  Button,
  CopyButton,
  Flex,
  rem,
  Tooltip,
} from "@mantine/core";
import { formatStampDetails } from "../formatStampDetails";
import { IconCheck, IconCopy } from "@tabler/icons-react";

// Function for creating data
export const collectionUnPinnedTableData = (
  contentCollectionsUnPinned,
  setCollectionID,
  setIsPinned,
  setOpenedModalPinned,
  theme,
) => {
  if (!Array.isArray(contentCollectionsUnPinned)) {
    return [];
  }

  return contentCollectionsUnPinned.map((item) => ({
    name: item.name,
    cid: (
      <Flex align="center" gap={2}>
        {formatStampDetails(item.cid)}
        <CopyButton value={item.cid} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? "Copied" : "Copy"}
              withArrow
              position="right"
            >
              <ActionIcon
                color={copied ? "teal" : "gray"}
                variant="subtle"
                onClick={copy}
              >
                {copied ? (
                  <IconCheck style={{ width: rem(16) }} />
                ) : (
                  <IconCopy style={{ width: rem(16) }} />
                )}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Flex>
    ),
    action: (
      <Button
        type="button"
        variant="transparent"
        color={theme.customColors.blackLight}
        styles={() => ({
          root: {
            fontSize: "1em",
            textDecoration: "underline",
            paddingLeft: "0",
            width: 90,
            textAlign: "left",
          },
        })}
        onClick={() => {
          setIsPinned(true);
          setCollectionID(item.id);
          setOpenedModalPinned(true);
        }}
      >
        Pin
      </Button>
    ),
  }));
};

// Function for creating columns
export const collectionUnPinnedTableColumns = () => [
  {
    accessorKey: "name",
    header: "Collection Name",
    enableSorting: true,
    size: 450,
    sortingFn: (rowA, rowB) => {
      const nameA = rowA.original.name.toLowerCase();
      const nameB = rowB.original.name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "cid",
    header: "Collection ID",
    size: 100,
    sortingFn: (rowA, rowB) => {
      const cidA = rowA.original.cid.props?.children?.[0] || "";
      const cidB = rowB.original.cid.props?.children?.[0] || "";
      if (cidA < cidB) return -1;
      if (cidA > cidB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
    size: 40,
    mantineTableBodyCellProps: {
      align: "right",
    },
  },
];

// Function for configuring the table
export const collectionUnPinnedTableOptions = (
  columns,
  data,
  faIcons,
  theme,
) => ({
  columns,
  data,
  icons: faIcons,
  paginationDisplayMode: "pages",
  enableFullScreenToggle: false,
  enableTopToolbar: false,
  enableBottomToolbar: true,
  enableColumnActions: false,
  initialState: { pagination: { pageIndex: 0, pageSize: 12 } },
  withColumnBorders: true,
  mantinePaginationProps: () => ({
    showRowsPerPage: false,
    color: theme.customBackgrounds.table,
    autoContrast: true,
    style: {
      paddingBottom: "1.75em",
    },
  }),
  mantineTableProps: {
    style: {
      border: "none",
      borderCollapse: "collapse",
    },
    className: "html-table html-table-custom",
  },
  mantinePaperProps: {
    withBorder: false,
    shadow: "none",
    className: "html-table-custom-wrap",
  },
  mantineTableBodyCellProps: {
    style: {
      padding: "0.6875em 0.9375em 0.5625em 0.9375em",
    },
  },
  mantineTableHeadCellProps: {
    style: {
      padding: "0.55em 0.9375em 0.4375em 0.9375em",
    },
  },
  mantineTableBodyRowProps: () => ({
    style: {
      borderBottom: theme.customColors.tableBorder,
    },
  }),
  mantineTableHeadRowProps: () => ({
    style: {
      backgroundColor: theme.customBackgrounds.table,
    },
  }),
});
