import JSZip from "jszip";
import { sha3_256_hash_bytes } from "../cryptoUtils";
import { notifications } from "@mantine/notifications";
import { parseMetadata } from "./parseMetadata";
import dayjs from "dayjs";
// Read and parse a ZIP file asynchronously
const readZipFile = async (zipFile) => {
  const zip = new JSZip();
  return zip.loadAsync(zipFile);
};

// Extract and format a timestamp from a CSV file name
const extractAndFormatTimestamp = (fileName) => {
  if (!fileName) {
    notifications.show({
      title: "Error",
      message: "File name is missing.",
      color: "red",
    });
    return null;
  }

  // Define supported patterns and formats
  const patterns = [
    { regex: /.*(\d{14})\.\w+$/, format: "YYYYMMDDHHmmss" }, // Full timestamp
    { regex: /.*(\d{12})\.\w+$/, format: "YYYYMMDDHHmm" }, // Without seconds
    { regex: /.*(\d{10})\.\w+$/, format: "YYYYMMDDHH" }, // Without minutes and seconds
    { regex: /.*(\d{8})\.\w+$/, format: "YYYYMMDD" }, // Without hours, minutes and seconds
  ];

  let matchedPattern = null;

  // Find the first matching pattern
  for (const pattern of patterns) {
    if (pattern.regex.test(fileName)) {
      matchedPattern = pattern;
      break;
    }
  }

  if (!matchedPattern) {
    notifications.show({
      title: "Error",
      message: "Invalid file name format.",
      color: "red",
    });
    return null;
  }

  // Extract the timestamp using a capturing group
  const timestampMatch = fileName.match(matchedPattern.regex);
  const timestampString = timestampMatch ? timestampMatch[1] : null;

  if (!timestampString) {
    notifications.show({
      title: "Error",
      message: "Unable to extract timestamp from file name.",
      color: "red",
    });
    return null;
  }

  // Parse the timestamp using the matched format
  let date;
  if (matchedPattern.format === "YYYYMMDD") {
    // For date-only formats, parse as UTC explicitly at midnight
    date = dayjs.utc(timestampString, matchedPattern.format, true);
  } else {
    // For other formats, parse normally
    date = dayjs(timestampString, matchedPattern.format, true);
  }

  if (!date.isValid()) {
    notifications.show({
      title: "Error",
      message: "Invalid timestamp in file name.",
      color: "red",
    });
    return null;
  }

  // Format the date to the desired output
  return date.utc().format("YYYY-MM-DD HH:mm:ss[+00:00]");
};

const stampFile = (filePath, arrayBuffer) => {
  const fileName = filePath.split("/").pop();
  const timestamp = extractAndFormatTimestamp(fileName);
  const byteArray = new Uint8Array(arrayBuffer);
  const hash = sha3_256_hash_bytes(byteArray);
  return `${timestamp},${hash}`;
};

// Generate a CSV file from the contents of a ZIP file
const generateCsvFromZip = async (zipContent) => {
  let csvMetadata = ""; // Metadata CSV content
  let csvOutput = "t,c\n"; // Data CSV header

  await Promise.all(
    Object.entries(zipContent.files).map(async ([filePath, file]) => {
      if (filePath.includes("__MACOSX")) return;

      if (filePath.endsWith("vbase_metadata.csv")) {
        const fileContent = await file.async("string");
        const lines = fileContent.split("\n");
        const { collectionName, userAddress } = parseMetadata(lines);

        if (collectionName && userAddress) {
          csvMetadata = `collection_name,user_address\n${collectionName},${userAddress}\n`;
        }
      } else {
        const arrayBuffer = await file.async("uint8array");
        const processedLine = stampFile(filePath, arrayBuffer);
        if (processedLine) {
          csvOutput += processedLine + "\n";
        }
      }
    }),
  );
  return csvMetadata + csvOutput;
};

// Process a ZIP file and return a formatted CSV string
export const processZip = async (zipFile) => {
  const zipContent = await readZipFile(zipFile);
  return generateCsvFromZip(zipContent);
};
